import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { triggerId: String }

  connect() {
    this.triggerElement = this.element.querySelector(`#${this.triggerIdValue}`)
    if (this.triggerElement) {
      this.triggerElement.addEventListener('toggle', this.toggle.bind(this))
    }
  }

  toggle(event) {
    this.element.dataset.open = event.target.open ? 'true' : 'false'
  }
}
