import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { scrollIntoView: Boolean }

  connect() {
    this.onToggle = this.onToggle.bind(this)
    this.element.addEventListener('toggle', this.onToggle)
  }

  disconnect() {
    this.element.removeEventListener('toggle', this.onToggle)
  }

  onToggle(event) {
    if (event.newState === 'open' && this.scrollIntoViewValue) {
      this.element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
}
